import { TAlert } from '../../generated/graphql';
import _ from 'lodash';
import { Icon, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';

type TAlertsPropsType = {
  alerts: TAlert[];
};

export const AlertsTooltip = ({ alerts }: TAlertsPropsType) => {
  if (!alerts?.length) return <></>;
  const filteredAlerts = _.uniqBy(alerts, 'message').filter((a) => !a?.acknowledgedAt);
  if (!filteredAlerts?.length) return <></>;
  return (
    <Tooltip message={filteredAlerts.map((a) => a?.message).join(' ')}>
      <Icon icon='warning' />
    </Tooltip>
  );
};
