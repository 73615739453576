import _ from 'lodash';
import { useMemo } from 'react';
import {
  TListRosterFilesByCompanyIdQuery,
  TListRosterFilesByCompanyIdQueryVariables,
  TListRosterFilesQuery,
  TListRosterFilesQueryVariables,
  TRosterFileFieldsFragment,
  useListRosterFilesByCompanyIdLazyQuery,
  useListRosterFilesLazyQuery,
} from '../../generated/graphql';
import { useQueryAll } from '../shared/useQueryAll';
import { useAllCompaniesMap } from '../companies/queries';

// LIST

export const useFullRosterFiles = <T extends TRosterFileFieldsFragment>(rosterFiles: T[]) => {
  const { data: companiesMap, error: companiesError, loading: companiesLoading } = useAllCompaniesMap();

  const fullRosterFiles = useMemo(() => {
    return rosterFiles.map((rf) => {
      return {
        ...rf,
        company: rf.companyId ? companiesMap[rf.companyId] : undefined,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesMap, JSON.stringify(rosterFiles)]);

  return {
    data: fullRosterFiles,
    error: companiesError,
    loading: companiesLoading,
  };
};

export type TRosterFileType<T extends TRosterFileFieldsFragment = TRosterFileFieldsFragment> = ReturnType<
  typeof useFullRosterFiles<T>
>['data'][number];

export const useQueryAllRosterFiles = (variables: TListRosterFilesQueryVariables = {}) => {
  const { data, error, loading } = useQueryAll<TListRosterFilesQuery, TListRosterFilesQueryVariables>({
    useQuery: useListRosterFilesLazyQuery,
    variables,
  });

  const {
    data: rosterFiles,
    error: fullError,
    loading: fullLoading,
  } = useFullRosterFiles(_.compact(data?.listRosterFiles.items));

  return {
    data: rosterFiles,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useQueryListRosterFileByCompanyId = (variables: TListRosterFilesByCompanyIdQueryVariables) => {
  const { data, error, loading } = useQueryAll<
    TListRosterFilesByCompanyIdQuery,
    TListRosterFilesByCompanyIdQueryVariables
  >({
    useQuery: useListRosterFilesByCompanyIdLazyQuery,
    variables,
  });

  const {
    data: fullRosterFiles,
    error: fullError,
    loading: fullLoading,
  } = useFullRosterFiles(_.compact(data?.listRosterFilesByCompanyId.items));

  const rosterFiles = _.orderBy(
    fullRosterFiles.filter((file) => !!file.impoortEndedAt),
    ['impoortEndedAt'],
    ['desc'],
  );
  const successfulRosterImports = rosterFiles.filter((file) => !file.importErrors);

  return {
    data: successfulRosterImports,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useQueryLatestRosterFileByCompanyId = (companyId: string) => {
  const { data: result, error, loading } = useQueryListRosterFileByCompanyId({ companyId });
  return { data: result[0], error, loading };
};
