import _ from 'lodash';
import { Icon, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import { AlertDismissButton } from './AlertDismissButton';
import { TShipmentType } from '../../entities/shipments/queries';
import { TGiftType } from '../../entities/gifts/queries';
import style from './AlertDismissButton.module.scss';
import clsx from 'clsx';

type TAlertsPropsType = {
  entity: TGiftType | TShipmentType;
  userEmail: string;
  updateMutation: any;
};

export const AlertTooltipDismiss = ({ entity, userEmail, updateMutation }: TAlertsPropsType) => {
  const alerts = entity.alerts;
  if (!alerts?.length) return <></>;
  const activeAlerts = _.uniqBy(alerts, 'message').filter((a) => !a?.acknowledgedAt);
  if (!activeAlerts?.length) return <></>;
  return (
    <>
      {activeAlerts.map((a, i) => {
        return (
          <div key={i} className={clsx(style.alertContainer)}>
            <Tooltip message={a?.message as string}>
              <Icon icon='warning' />
            </Tooltip>
            <AlertDismissButton
              entity={entity}
              message={a?.message as string}
              userEmail={userEmail}
              updateMutation={updateMutation}
            />
          </div>
        );
      })}
    </>
  );
};
