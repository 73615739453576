import { Icon, LoadingPage, Select, Switch, TextField } from '@chocolate-soup-inc/cs-frontend-components';
import { useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import _ from 'lodash';

import styles from '../EmployeesImport.module.scss';
import { useFragmentOrFetchCompany } from '../../../../entities/companies/queries';
import { useQueryLatestRosterFileByCompanyId } from '../../../../entities/rosterFiles/queries';
import clsx from 'clsx';

const basicRecipientAttributes = {
  firstName: 'First Name',
  birthDate: 'Birth Date',
  foodPreferences: 'Allergies & Dietary Restrictions',
};

const basicPersonAttributes = {
  ...basicRecipientAttributes,
  preferredFirstName: 'Preferred First Name',
  lastName: 'Last Name',
};

const employeeAttributes = {
  ...basicPersonAttributes,
  externalId: 'External ID',
  officeId: 'Office',
  hireDate: 'Hire Date',
  email: 'Email',
  phoneNumber: 'Phone Number',
  title: 'Title',
  organization: 'Organization',
  tShirtSize: 'T-Shirt Size',
  sweaterSize: 'Sweater Size',
  donateBirthdayGift: 'Donate Birthday Gift',
  donateWorkAnniversaryGift: 'Donate Work Anniversary Gift',
  'address.address1': 'Address 1',
  'address.address2': 'Address 2',
  'address.city': 'City',
  'address.state': 'State',
  'address.country': 'Country',
  'address.zipCode': 'Postal Code',
};

const partnerAttributes = {
  ...basicPersonAttributes,
  donateBirthdayGift: 'Donate Birthday Gift',
};
const childAttributes = basicPersonAttributes;
const petAttributes = {
  ...basicRecipientAttributes,
  petType: 'Type',
};

type TSecondStepProps = {
  companyId: string;
  file: File;
  mapping: Record<number, string | undefined>;
  setMapping: React.Dispatch<React.SetStateAction<Record<number, string | undefined>>>;
};

export const SecondStep = (props: TSecondStepProps) => {
  const { companyId, file, mapping, setMapping } = props;

  const [headers, setHeaders] = useState<string[]>();
  const [numberOfPartners, setNumberOfPartners] = useState<number>(0);
  const [numberOfChildren, setNumberOfChildren] = useState<number>(0);
  const [numberOfPets, setNumberOfPets] = useState<number>(0);

  const { data: company } = useFragmentOrFetchCompany({
    id: companyId,
  });

  const { data: dataImportRoster } = useQueryLatestRosterFileByCompanyId(companyId);

  const lastImportMapping = useMemo(() => {
    if (!dataImportRoster?.mapping) return undefined;
    return JSON.parse(dataImportRoster?.mapping);
  }, [dataImportRoster]);

  useEffect(() => {
    if (file) {
      file.arrayBuffer().then((data) => {
        const workbook = XLSX.read(data);
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const parsedData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        setHeaders(parsedData[0] as string[]);
      });
    }
  }, [file]);

  const mappingOptions = useMemo(() => {
    let options = Object.entries(employeeAttributes).reduce((agg, [key, value]) => {
      agg[key] = `Employee ${value}`;
      return agg;
    }, {} as Record<string, any>);

    for (let i = 0; i < numberOfPartners; i += 1) {
      options = {
        ...options,
        ...Object.entries(partnerAttributes).reduce((agg, [key, value]) => {
          agg[`partners.${i}.${key}`] = `Partner ${i + 1} ${value}`;
          return agg;
        }, {} as Record<string, any>),
      };
    }

    for (let i = 0; i < numberOfChildren; i += 1) {
      options = {
        ...options,
        ...Object.entries(childAttributes).reduce((agg, [key, value]) => {
          agg[`children.${i}.${key}`] = `Child ${i + 1} ${value}`;
          return agg;
        }, {} as Record<string, any>),
      };
    }

    for (let i = 0; i < numberOfPets; i += 1) {
      options = {
        ...options,
        ...Object.entries(petAttributes).reduce((agg, [key, value]) => {
          agg[`pets.${i}.${key}`] = `Pet ${i + 1} ${value}`;
          return agg;
        }, {} as Record<string, any>),
      };
    }

    return options;
  }, [numberOfChildren, numberOfPartners, numberOfPets]);

  if (headers == null) return <LoadingPage />;

  return (
    <div className={styles.secondStep}>
      {(company?.significantOtherBirthdayActivated ||
        company?.childBirthdayActivated ||
        company?.petBirthdayActivated) && (
        <>
          <h2 className={styles.subTitle}>Relationship Settings</h2>
          <p className={styles.paragraph}>
            Set the number of partners, children and pets present on the roster being imported.
          </p>
          <div className={styles.mappingSettingsContainer}>
            {company?.significantOtherBirthdayActivated && (
              <TextField
                clearDisabled={true}
                label='Partners'
                name='numberOfPartners'
                type='number'
                multiline={false}
                onChange={(v) => {
                  setNumberOfPartners(v ? parseInt(v) : 0);
                }}
                value={numberOfPartners?.toString()}
              />
            )}
            {company?.childBirthdayActivated && (
              <TextField
                clearDisabled={true}
                label='Children'
                name='numberOfChildren'
                type='number'
                multiline={false}
                onChange={(v) => {
                  setNumberOfChildren(v ? parseInt(v) : 0);
                }}
                value={numberOfChildren?.toString()}
              />
            )}
            {company?.petBirthdayActivated && (
              <TextField
                clearDisabled={true}
                label='Pets'
                name='numberOfPets'
                type='number'
                multiline={false}
                onChange={(v) => {
                  setNumberOfPets(v ? parseInt(v) : 0);
                }}
                value={numberOfPets?.toString()}
              />
            )}
          </div>
        </>
      )}
      <h2 className={styles.subTitle}>Mappings</h2>
      <div>
        {lastImportMapping && (
          <div className={clsx(styles.formToggle)}>
            <Switch
              name='useLastImportMapping'
              label='Use Last Import Mapping?'
              disabled={!lastImportMapping}
              onChange={(v) => {
                setMapping((prev) => ((v as boolean) ? { ...prev, ...lastImportMapping } : {}));
              }}
            />
          </div>
        )}
        {headers.map((header, index) => {
          return (
            <div className={styles.headerMappingRow} key={index}>
              <span className={styles.headerMappingRowText}>
                Column {index + 1}: {header}
              </span>
              <Icon icon='arrow_forward' />
              <Select
                autoComplete='off'
                includeEmptyOption={true}
                name={`mapping[${index}]`}
                multiple={false}
                onChange={(v) => {
                  setMapping((currentMapping) => {
                    if (v == null) {
                      delete currentMapping[index];
                    } else {
                      currentMapping[index] = v as string;
                    }
                    return _.cloneDeep(currentMapping);
                  });
                }}
                options={Object.entries(mappingOptions)
                  .filter(([k]) => {
                    if (mapping[index] === k) return true;
                    return !Object.values(mapping).includes(k);
                  })
                  .map(([k, v]) => ({
                    label: v,
                    value: k,
                  }))}
                variant='outlined'
                value={mapping[index] ?? null}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
